<template>
  <div id="app" class="d-flex flex-column h-100">
    <nav class="navbar navbar-expand navbar-dark bg-accent p-2">
      <div class="container">
        <a
          class="navbar-brand"
          href="http://www.arizona.edu"
          title="The University of Arizona"
        >
          <img
            style="width: 250px"
            alt="The University of Arizona"
            src="https://assets.cdn.fso.arizona.edu/ua_wordmark_line_logo_white_rgb.svg"
          />
        </a>
        <button
          v-if="$router.currentRoute.name === 'Admin'"
          type="button"
          class="btn btn-primary"
          @click="$router.push('/')"
        >
          Home
        </button>
        <!-- This has been commented out to prevent people from trying to
        access admin and locking themselves. -->
        <button
          v-else
          type="button"
          class="btn btn-primary"
          @click="$router.push('/admin')"
        >
          Admin
        </button>
      </div>
    </nav>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import "@uarizona-fnsv/bootstrap/dist/bootstrap.css"

import Footer from "./components/Footer.vue"

export default {
  name: "App",

  components: {
    Footer,
  },

  mounted: function () {
    if (this.$auth.authorized) {
      this.$router.push("/admin")
    }
  },
}
</script>
