import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      meta: { title: "Home" },
      component: () => import("./views/Home.vue"),
    },
    {
      path: "/admin",
      name: "Admin",
      component: () => import("./views/Admin.vue"),
    },
  ],
})

router.beforeEach((to, from, next) => {
  document.title = to?.meta.title
    ? `catcard_orientation | ${to.meta.title}`
    : "catcard_orientation"
  next()
})

export default router
